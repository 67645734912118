<template>
  <div class="serveManage">
    <ds-header title="权益身份绑定"></ds-header>
    <a-form layout="inline">
      <a-form-item label="权益名称：">
        <a-input v-model.trim="searchForm.benefitTitle" placeholder="请输入权益名称" class="inputClass"/>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search"/>查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <div class="upload-button-class">
        <div>
          <a-upload
              name="file"
              accept=".xlsx, .xls"
              :multiple="true"
              action="/api/dscloud-appservice-management/web/benefit/import-excel"
              :headers="headers"
              @change="uploadFile"modularManagement
          >
            <a-button
                type="primary"
                class="uploadClass"
            >
              <a-icon type="upload" />上传文件
            </a-button>
          </a-upload>
        </div>
        <div>
          <a-button type="primary" @click="downLoadAllData">
            <a-icon type="download" />导出所有数据
          </a-button>
        </div>

        <div>
          <a-upload
              name="file"
              accept=".xlsx, .xls"
              :multiple="true"
              action="/api/dscloud-appservice-management/web/benefit/import-excel-update"
              :headers="headers"
              @change="uploadFile"
          >
            <a-button
                type="primary"
                class="uploadClass"
            >
              <a-icon type="upload" />上传修改权益
            </a-button>
          </a-upload>
        </div>
      </div>
      <p style="color: red; margin-top: 10px">上传文件数据会批量覆盖，请确认好后上传！<a @click="downLoadExcel">点击下载模版</a></p>
    </div>
    <div style="padding-top: 15px">
      <a-table :columns="columns" @change="pageChange" :pagination="false" :data-source="data" bordered>
        <template
            slot="benefitImage"
            slot-scope="text, record"
        >
          <div v-if="record.editable">
            <a-upload
                name="file"
                list-type="picture-card"
                :show-upload-list="false"
                :action="IMG_API + '/oss/files'"
                @change="handleImgChange"
                :before-upload="beforeUpload"
            >
              <div v-if="text  === ''">
                <a-icon type="plus"/>
                <div class="ant-upload-text">Upload</div>
              </div>
              <img :src="text" width="160px" height="115px" v-else/>
            </a-upload>
          </div>
          <img v-else :src="text" width="160px" height="100px"/>
        </template>
        <template
            slot="crowdIdentity"
            slot-scope="text, record"
        >
          <div v-if="record.editable">
            <a-select
                mode="multiple"
                placeholder="请选择"
                style="width: 300px"
                v-model="nowData.crowdIdentity"
                @change="handleCrowdChange"
            >
              <a-select-option v-for="(item, index) in allCrowd" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <span v-else>{{ convertData(text) }}</span>
        </template>
        <template
            slot="remark"
            slot-scope="text, record"
        >
          <div v-if="record.editable">
            <a-input v-model="nowData.remark"/>
          </div>
          <span v-else>{{ text }}</span>
        </template>
        <template slot="operation" slot-scope="text, record, index">
          <div class="editable-row-operations">
        <span v-if="record.editable">
          <a @click="() => save(record)">保存</a>
          <a-popconfirm title="确定要取消吗?" @confirm="() => cancel(record)">
            <a>取消</a>
          </a-popconfirm>
        </span>
            <span v-else>
          <a :disabled="editDisabled" @click="() => edit(record)">编辑</a>
        </span>
          </div>
        </template>
      </a-table>
      <div class="pagination-class">
        <a-pagination
            :show-total="total => `共 ${total} 条`"
            :page-size="pagination.pageSize"
            :default-current="pagination.current"
            :total="total"
            @change="pageChange"
            @showSizeChange="paginationSizeChange"
            show-quick-jumper
            showSizeChanger
        />
      </div>
    </div>
  </div>
</template>


<script>
import {mapState} from "vuex";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import DSTable from "@/components/DSComponents/DSTable";
import {IMG_API} from "@/config";
import {findAllCrowd, getEquityIdentityBindingList, updateEquityIdentityBinding, downLoadAllEquityData} from '../../../api/equityManagement'

const data = [];
export default {
  name: "serveManage",
  components: {
    DSTable,
    ConfirmBoxModule,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
  },
  data() {
    this.cacheData = data.map(item => ({...item}));
    return {
      headers: { Authorization: localStorage.getItem("user").accessToken },
      // 当前处于编辑状态的数据
      nowData: {},
      // 编辑按钮是否可用
      editDisabled: false,
      data,
      total: 0,
      IMG_API: IMG_API,
      // 上传图片loading
      loading: false,
      // 所有人群
      allCrowd: [],
      editingKey: '',
      searchForm: {
        benefitTitle: ''
      },
      title: "",
      id: "",
      pagination: {
        current: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: '权益名称',
          dataIndex: 'benefitTitle',
          width: '15%',
        },
        {
          title: '权益图片',
          dataIndex: 'benefitImage',
          width: '15%',
          scopedSlots: {customRender: 'benefitImage'},
        },
        {
          title: '人群分类',
          dataIndex: 'crowdIdentity',
          width: '30%',
          scopedSlots: {customRender: 'crowdIdentity'},
        },
        {
          title: '描述',
          dataIndex: "remark",
          width: '30%',
          scopedSlots: {customRender: 'remark'},
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
        },
      ]
    };
  },
  mounted() {
    this.searchFormList();
    // 获取所有人群
    this.getAllCrowd()
  },
  methods: {
    // 列表
    searchFormList() {
      getEquityIdentityBindingList(this.searchForm, this.pagination.current, this.pagination.pageSize).then(res => {
        if (res.code === '200') {
          this.data = res.data.items
          this.total = res.data.total
          this.editDisabled = false
        } else {
          this.$message.error("获取列表失败")
        }
      })
    },
    // 下载模版
    downLoadExcel () {
      let url =
          "https://oss-workplace-prod.innoecos.cn/picture_prod/052d380a14810000_%E6%9D%83%E7%9B%8A%E6%B1%A0%E8%A1%A8%E6%A0%BC%E6%A8%A1%E6%9D%BF.xlsx"
      window.open(url);
    },
    //查询
    search() {
      // this.table.pagination.current = 1;
      this.searchFormList();
    },
    // 根据人群id转化数据
    convertData(data) {
      let str = ''
      if (data) {
        if (this.allCrowd.length > 0) {
          this.allCrowd.forEach(item => {
            data.forEach(arr => {
              if (arr === item.id) {
                str += item.name
                str += ','
              }
            })
          })
        }
      }
      return str;
    },
    // 获取所有人群
    getAllCrowd() {
      findAllCrowd().then(res => {
        if (res.code === '200') {
          this.allCrowd = res.data
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    // 人群change事件
    handleCrowdChange(val) {
        const newData = [...this.data];
        const target = newData.find(item => item.editable);
        if (target) {
          target['crowdIdentity'] = val;
          this.data = newData;
        }
    },
    // 上传文件
    uploadFile (info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} 上传成功`);
        this.search();
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },

    //重置
    resetSearchForm() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.searchForm.benefitTitle = "";
      this.searchFormList();
    },

    //分页操作
    pageChange(page, pageSize) {
      this.pagination.current = page
      this.pagination.pageSize = pageSize
      this.searchFormList();
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.key);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    // 导出所有数据
    downLoadAllData () {
      downLoadAllEquityData().then(res => {
        if(res.status == 200){
          const blob = new Blob([res.data]);
          const objectUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = objectUrl;
          a.setAttribute("download","权益修改表.xlsx")
          a.click();
        }
      })
    },
    // 图片上传
    handleImgChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.loading = false;
        const newData = [...this.data]
        const target = newData.find(item => item.editable)
        if (target) {
          target.benefitImage = info.file.response.redirect_uri;
        }
        const data = this.data.find((item) => {

        })

      }
    },
    // 图片限制
    beforeUpload(file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、png格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.size / 1024 / 1024 <= 2;
      if (!isLt2M) {
        this.$message.error("图片最大2M");
        return Promise.reject();
      }
      // 校验比例
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = true;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
          () => {
            return file;
          },
          () => {
            this.$message.error("图片不规范，请按要求上传");
            return Promise.reject();
          }
      );
      return isJpgOrPng && isLt2M && isSize;
    },
    edit(data) {
      this.editDisabled = true
      const newData = [...this.data];
      const target = newData.find(item => data.id === item.id);
      this.editingKey = data.id;
      if (target) {
        this.nowData = target
        target.editable = true;
        this.data = newData;
      }
    },
    // 编辑保存
    save(data) {
      if (data.crowdIdentity === "") {
        this.$message.error("请选择人群分类！")
        return;
      }
      updateEquityIdentityBinding(data).then(res => {
        if (res.code === '200') {
          this.$message.success("操作成功")
          this.searchFormList()
        } else {
          this.$message.error("操作失败")
        }
      })
    },
    // 取消保存
    cancel() {
      this.searchFormList()
    },
    paginationSizeChange(current, size) {
      this.pagination.current = current
      this.pagination.pageSize = size
      this.searchFormList()
    }
  },
};
</script>

<style lang="scss" scoped>
.inputClass {
  width: 200px;
}
.upload-button-class {
  display: flex;
  & > div {
    padding-right: 15px;
  }
}

.editable-row-operations a {
  margin-right: 8px;
}

.pagination-class {
  padding-top: 20px;
  display: flex;
  flex-direction: row-reverse;
}

</style>
